import { zhHans } from 'vuetify/lib/locale'

export default {
  ...zhHans,

  header_title: {
    inquiry: '咨询',
    booking: '来场预约',
    event: '活动信息',
    about: '关于RAKUMARU',
    event_detail: '活动详情',
    event_booking: '活动预约',
    news_list: '新闻列表',
    news_detail: '新闻詳細',
    company_summary: '公司概要',
    privacy: '隐私政策'
  },
  
  bottom_nav: {
    inquiry: '咨询',
    booking: '来场预约',
    //event: '活动信息',
    event: '企划中',
  },

  router_button: {
    about: '关于RAKUMARU',
    news: '新闻列表',
    support: '详细内容'
  },

  menu: {
    about: '关于RAKUMARU不动产',
    concept: '概念',
    reason_selected: '选择理由',
    event: '活动信息',
    support: '支持',
    guarantee: '售后服务・保证',
    frequently_asked_questions: '常见问题',
    staff_introduce: '员工介绍',
    company_summary: '公司概要',
    recent_news: '最近新闻',
    visit_booking: '访问预约',
    inquiry: '咨询',
    privacy_policy: '隐私政策'
  },

  form: {
    input: '输入',
    confirm: '确认',
    complete: '完成',
    required: '必填',
    optional: '任意',
    btn_confirm: '确认',
    btn_submit: '提交',
    btn_back: '返回',
    btn_back_home: '返回主页面'
  },

  dialog: {
    already_reserved: '您已经预约过这个活动了',
    close: '关闭'
  },

  inquiry: {
    name: '姓名',
    ph_name: '例）于 德水',
    furigana: '假名',
    ph_furigana: '例）う とくすい',
    mobile: '手机电话',
    ph_mobile: '例）090-1234-5678',
    mail: '邮箱地址',
    ph_mail: '例）yourname@domain.com',
    post_code: '邮编',
    ph_post_code: '例）3520003',
    prefecture: '都道府县',
    ph_prefecture: '例）长野县',
    address: '住址',
    ph_address: '例）埼玉县新座市北野3-13-6',
    question: '问题・要求',
    ph_question: '请输入要咨询的内容。',
    v_name: '请输入姓名',
    v_furigana: '请输入假名',
    v_mobile: '请输入手机号码',
    v_mail: '请输入邮箱地址',
    v_mail_invalid: '请输入正确的邮箱地址',
    v_post_code: '请输入邮政编码',
    v_prefecture: '请选择都道府县',
    v_address: '请输入居住地址',
    v_question: '请输入咨询内容',
    v_question_over: '超过500字',
    complete: '提交成功，感谢您的咨询。'
  },

  booking: {
    visit_day: '访问日',
    ph_visit_day: '请从日历中选择访问日',
    visit_time: '开始时间',
    ph_visit_time: '请从钟里选择访问时间',
    name: '姓名',
    ph_name: '例）于 德水',
    furigana: '假名',
    ph_furigana: '例）う とくすい',
    mobile: '手机电话',
    ph_mobile: '例）090-1234-5678',
    mail: '邮箱地址',
    ph_mail: '例）yourname@domain.com',
    post_code: '邮编',
    ph_post_code: '例）3520003',
    prefecture: '都道府县',
    ph_prefecture: '例）长野县',
    address: '住址',
    ph_address: '例）埼玉县新座市北野3-13-6',
    question: '问题・要求',
    ph_question: '请输入要咨询的内容。',
    v_name: '请输入姓名',
    v_furigana: '请输入假名',
    v_mobile: '请输入手机号码',
    v_mail: '请输入邮箱地址',
    v_mail_invalid: '请输入正确的邮箱地址',
    v_post_code: '请输入邮政编码',
    v_prefecture: '请选择都道府县',
    v_address: '请输入居住地址',
    v_question: '请输入咨询内容',
    v_question_over: '超过500字',
    v_visit_day: '请选择访问日',
    v_visit_time: '请选择访问时间',
    complete: '预约成功，感谢您的预约。'
  },

  event_booking: {
    event_name: '活动名',
    event_day: '活动日',
    event_time: '活动时间',
    name: '姓名',
    ph_name: '例）于 德水',
    furigana: '假名',
    ph_furigana: '例）う とくすい',
    mobile: '手机电话',
    ph_mobile: '例）090-1234-5678',
    mail: '邮箱地址',
    ph_mail: '例）yourname@domain.com',
    post_code: '邮编',
    ph_post_code: '例）3520003',
    prefecture: '都道府县',
    ph_prefecture: '例）长野县',
    address: '住址',
    ph_address: '例）埼玉县新座市北野3-13-6',
    question: '问题・要求',
    ph_question: '请输入要咨询的内容。',
    v_name: '请输入姓名',
    v_furigana: '请输入假名',
    v_mobile: '请输入手机号码',
    v_mail: '请输入邮箱地址',
    v_mail_invalid: '请输入正确的邮箱地址',
    v_post_code: '请输入邮政编码',
    v_prefecture: '请选择都道府县',
    v_address: '请输入居住地址',
    v_question: '请输入咨询内容',
    v_question_over: '超过500字',
    complete: '预约成功，感谢您的预约。'
  },

  event: {
    title: '的活动'
  },

  event1: {
    title: '开业纪念活动，福利多多。',
    content: '福利拿到手软！！福利拿到手软！！福利拿到手软！！福利拿到手软！！福利拿到手软！！福利拿到手软！！福利拿到手软！！福利拿到手软！！福利拿到手软！！福利拿到手软！！'
  },
  event2: {
    title: '超人气！！！住宅贷款说明会',
    content: '活动无敌好，速来！！活动无敌好，速来！！活动无敌好，速来！！活动无敌好，速来！！活动无敌好，速来！！活动无敌好，速来！！活动无敌好，速来！！活动无敌好，速来！！'
  },
  event3: {
    title: '样板间参观会，富士见市大人气！！！',
    content: '好房等你参观，速来！！！好房等你参观，速来！！！好房等你参观，速来！！！好房等你参观，速来！！！好房等你参观，速来！！！好房等你参观，速来！！！好房等你参观，速来！！！'
  },

  event_detail: {
    column1: '开始时间',
    column2: '预约 / 状态',
    reservation: '预约这个时间',
    success: '名额多',
    warning: '可预约',
    error: '名额少',
    grey: '不可预约'
  },

  footer: {
    address: '埼玉县ふじみ野市うれし野2 - 10 - 37 トナリエふじみ野T2 - 2',
    inquiry: '欢迎来电咨询',
    business_hours: '营业时间',
    day_off: '（周日定休）'
  },
  
  key1: "你好！",
  key2: "键２　国际化",

  namespace: {
    key3: "键３　<br/>国际化"
  }
}