import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Inquiry from "../views/Inquiry.vue"
import Booking from "../views/Booking.vue"
import Event from "../views/Event.vue"
import EventDetail from "../views/EventDetail.vue"
import EventBooking from "../views/EventBooking.vue"
import NewsList from "../views/NewsList.vue"
import NewsDetail from "../views/NewsDetail.vue"
import CompanySummary from "../views/CompanySummary.vue"
import Privacy from "../views/Privacy.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/inquiry",
    name: "Inquiry",
    component: Inquiry,
  },
  {
    path: "/booking",
    name: "Booking",
    component: Booking,
  },
  {
    path: "/event",
    name: "Event",
    component: Event,
  },
  {
    path: "/event/:id",
    name: "EventDetail",
    component: EventDetail,
  },
  {
    path: "/event-booking",
    name: "EventBooking",
    component: EventBooking,
  },
  {
    path: "/news",
    name: "NewsList",
    component: NewsList,
  },
  {
    path: "/news/:id",
    name: "NewsDetail",
    component: NewsDetail,
  },
  {
    path: "/company-summary",
    name: "CompanySummary",
    component: CompanySummary,
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
