<template>
  <v-app>

    <v-main v-scroll="getScrollTop">

      <router-link :to="{name: 'Home'}">
        <v-img src="./assets/logo.jpg" />
      </router-link>

      <router-view />

      <v-fab-transition>
        <ChangeLanguage v-show="showTranslation" />
      </v-fab-transition>

      <BottomNav />

    </v-main>

    
  </v-app>
</template>

<script>

import ChangeLanguage from "./components/ChangeLanguage.vue"
import BottomNav from "./components/BottomNav.vue"

export default {
  name: "App",

  data: () => ({
    showTranslation: true,
  }),
  methods: {
    getScrollTop() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      
      if(scrollTop > 5) {
        this.showTranslation = false
      } else {
        this.showTranslation = true
      }
    },
  },
  components: {
    ChangeLanguage,
    BottomNav
  }
};
</script>

<style scoped>
    /* fix bottom navigation problem */
    /* .v-item-group.v-bottom-navigation .v-btn.v-size--default {
        height: inherit;
    } */
</style>
