<template>
  <v-card flat>
    <div class="yellow--text text--darken-4 text-center text-h5 font-weight-bold">CONCEPT</div>
    <div class="text-center font-weight-bold text-h5 mt-4">
      理想の家さがし<br/>
      私だちが支える
    </div>
    <div class="text-center font-weight-medium text-subtitle-1 mt-4 mx-4">
       RAKUMARU 不動産では、<br />ご希望をじっくりお伺いし、<br />
       お客様にかわって「物件探し」をいたします。<br />
       時には長いお付き合いになるかもしれません。<br />
       でも、最適な物件が見つかるまで、<br />
       「もういいよ」とお声かけいただくまで、<br />
       私たちはあきらめません。<br />
       親切で正直は当たり前、<br />
       でもその当然のことに真剣に<br />
       取り組んでいるのが RAKUMARU です。<br />
       お客様に納得していただけるまで、<br />
       ご提案を続けてまいります。
    </div>
  </v-card>
</template>

<script>

export default {
  name: "Concept",

  data: () => ({
   
  }),

}
</script>
