<template>
  <div>
    <v-container>
      <Header :title="$vuetify.lang.t('$vuetify.header_title.company_summary')"/>

      <v-sheet dark class="text-subtitle-1 text-center font-weight-bold mt-8 mx-8 pt-2 pb-1" shaped>
        代表挨拶
      </v-sheet>
      <div class="text-subtitle-2 text-center mt-4">
        ホームページに<br/>
        ご訪問いただきありがとうございます。<br />
        株式会社未来アクトの宮本です。
        ここは後で入れおく<br />
        ここは後で入れおく<br />
        ここは後で入れおく<br />
        ここは後で入れおく<br />
        ここは後で入れおく<br />
        今後も初心を忘れず、<br />
        明るい未来へ繋いでいける存在を目指し続け、<br />
        地域社会に貢献できるよう一歩ずつ歩んでいきます。
      </div>

      <v-sheet dark class="text-subtitle-1 text-center font-weight-bold mt-8 mx-8 pt-2 pb-1" shaped>
        会社概要
      </v-sheet>

      <v-card class="mx-4 mt-8" flat>
        <v-row>会社名</v-row>
        <v-row>株式会社未来アクト</v-row>
        <v-row>所在地</v-row>
        <v-row>埼玉県ふじみ野市うれし野2 - 10 - 37 - T2 - 2</v-row>
        <v-row>電話番号</v-row>
        <v-row>049 - 211 - 6863</v-row>
        <v-row>FAX番号</v-row>
        <v-row>050 - 3183 - 9509</v-row>
        <v-row>代表取締役</v-row>
        <v-row>宮本誠</v-row>
        <v-row>法人設立</v-row>
        <v-row>2014年11月19日</v-row>
        <v-row>資本金</v-row>
        <v-row>2000万</v-row>
        <v-row>許可・登録</v-row>
        <v-row>埼玉県知事(1)第24660号</v-row>
        <v-row>加盟団体</v-row>
        <v-row>
          全日本不動産協会 埼玉県本部 会員<br/>
          不動産方法協会 埼玉県本部 会員
        </v-row>
        <v-row>事業内容</v-row>
        <v-row style="height:110px;border-bottom:1px solid #a8a8a8" class="mb-4">
          ・不動産販売、管理<br />
          ・飲食店経営<br />
          ・旅行会社<br />
          ・医療
        </v-row>
      </v-card>

    </v-container>
  </div>
</template>

<script>
import Header from "../components/Header.vue"

export default {
  name: "Privacy",

  data: () => ({
    
  }),
  components: {
    Header
  }

}
</script>

<style lang="scss" scoped>
  .row {
    border:1px solid #a8a8a8;
    border-bottom: none;
    padding-top:10px;
    padding-bottom: 8px;
  }
  
  div.row:nth-child(odd) {
    justify-content: center;
    background-color:#dedede;
    font-size: 15px;
    font-weight: bold;
  }

  div.row:nth-child(even) {
    padding-left: 10px;
    font-size:15px;
  }
</style>
