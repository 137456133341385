<template>
  <v-card flat class="mt-8">
    <div class="brown--text text--darken--2 text-center text-h5 font-weight-bold">COMPANY</div>
    <div class="mt-6 mx-4">
      <v-img src="../assets/company.jpeg" @click="$vuetify.goTo(0,{duration:0});$router.push({name: 'CompanySummary'})"/>
    </div>
  </v-card>
</template>

<script>

export default {
  name: "Company",

  data: () => ({
   
  }),

}
</script>
