<template>
  <v-card flat style="background-color:#f9f7f1;">
    <div class="mt-6">
      <v-img src="../assets/footer_logo.jpg" />
      <div class="text-overline text-center">
        <div>〒356 - 0056</div>
        <div class="mt-n3">{{ $vuetify.lang.t('$vuetify.footer.address') }}</div>
      </div>
    </div>
    <v-divider class="black mx-4 mt-4"/>
    <div>
      <div class="d-flex justify-center font-weight-bold pt-4">{{ $vuetify.lang.t('$vuetify.footer.inquiry') }}</div>
      <div class="d-flex justify-center mt-4">
        <v-icon large class="grey--text text--darken-3">mdi-phone</v-icon>
        <span class="ml-4 text-h5 font-weight-bold">
          <a href="tel:0492116863" class="text-decoration-none grey--text text--darken-4">049 - 211 - 6863</a>
        </span>
      </div>
      <div class="d-flex justify-center text-overline">
        {{ $vuetify.lang.t('$vuetify.footer.business_hours') }}
        ：10:00 〜 18:00
        {{ $vuetify.lang.t('$vuetify.footer.day_off') }}
      </div>
    </div>
    <v-divider class="black mx-4 mt-4"/>
    
    <div class="d-flex justify-center text-overline mt-10">
      FUTURE ACT.All Rights Reserved.
    </div>
    
  </v-card>
</template>

<script>

export default {
  name: "Footer",

  data: () => ({
   
  }),

}
</script>
