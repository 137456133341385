<template>
  
  <v-btn class="font-weight-black" color="#272727" min-width="70px" width="70px">
   <input class="checkbox-toggle" type="checkbox" @click="menuClicked"/>
   <div class="hamburger">
     <div></div>
   </div>
  </v-btn>
  
</template>

<script>
export default {
  name: "HamburgerMenu",

  data: () => ({
    dialog: false
  }),
  methods: {
    menuClicked() {
      this.$emit('menuClick', event.target.checked)
    },
    cbClick() {
      document.getElementsByClassName('checkbox-toggle')[0].click()
    }
  }
};
</script>

<style lang="scss" scoped>

  $hamburger-menu-size: 36px;
  $anim-duration: .4s;
  $hamburger-menu-bg-color:#272727;
  $hamburger-menu-border-radius: .12em;
  $hamburger-menu-line-height: 2px;
  $main-color: #FEFEFE;

  .checkbox-toggle {
    position: absolute;
    //top: 0;
    //left: 0;
    z-index: 2;
    cursor: pointer;
    width: $hamburger-menu-size;
    height: $hamburger-menu-size;
    opacity: 0;
    
    &:checked {
      & + .hamburger {
        > div {
          transform: rotate(135deg);
          
          &:before,
          &:after {
            top: 0;
            transform: rotate(90deg);
          }
          
          &:after {
            opacity: 0;
          }
        }
      }
      
      /*
      & ~ .menu  {
        pointer-events: auto;
        visibility: visible;
        
        > div {
          transform: scale(1);
          transition-duration: .75s;
          
          > div {
            opacity: 1;
            transition: opacity $anim-duration ease $anim-duration;
          }
        }
      }*/
    }
    
    &:hover + .hamburger {
      box-shadow: inset 0 0 10px rgba(0, 0, 0, .1);
    }
    
    &:checked:hover + .hamburger > div {
      transform: rotate(225deg);
    }
  }
  
  .hamburger {
    //position: absolute;
    //top: 0;
    //left: 0;
    z-index: 1;
    width: $hamburger-menu-size;
    height: $hamburger-menu-size;
    //padding: .5em 1em;
    background: $hamburger-menu-bg-color;
    border-radius: 0 $hamburger-menu-border-radius $hamburger-menu-border-radius 0;
    cursor: pointer;
    transition: box-shadow $anim-duration ease;
    backface-visibility: hidden;
    //@extend .flex-center;
    display: flex;
    align-items: center;
    justify-content: center;
    
    > div {
      position: relative;
      flex: none;
      width: 100%;
      height: $hamburger-menu-line-height;
      background: $main-color;
      transition: all $anim-duration ease;
      //@extend .flex-center;
      
      
      &:before,
      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: -$hamburger-menu-size / 4;
        left: 0;
        width: 100%;
        height: $hamburger-menu-line-height;
        background: inherit;
        transition: all $anim-duration ease;
      }
      
      &:after {
        top: $hamburger-menu-size / 4;
      }
    }
  }

</style>