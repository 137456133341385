<template>
  
  <v-dialog
      v-model="show"
      fullscreen
      hide-overlay
      transition="slide-y-reverse-transition"
  >
    <v-container class="white">
      <router-link :to="{name: 'Home'}" @click.native="bannerClicked">  
        <v-img src="../assets/logo.jpg" class="mb-2"/>
      </router-link>

      <template v-for="(item, index) in menu">
        <v-row 
        class="font-weight-black mx-1 mb-4 pt-5"
        :class="index > 0 ? 'border' : ''"
        :key="item.parent.title"
        @click="routerClicked(item.parent.link)"
      >
          {{ $vuetify.lang.t('$vuetify.menu.' + item.parent.title) }}
          <v-spacer />
        </v-row>

        <v-row 
          class="mx-1 pl-3 py-3 border"
          v-for="child in item.children"
          :key="child.title"
          @click="routerClicked(child.link)"
        >
          {{ $vuetify.lang.t('$vuetify.menu.' + child.title) }}
          <v-spacer />
        </v-row>

      </template>

      <v-btn block dark rounded class="orange font-weight-black text-h6 py-8 mt-8"
        @click="$emit('routerClick');$router.push({name: 'Booking'})"
      >
        {{ $vuetify.lang.t('$vuetify.menu.visit_booking') }}
      </v-btn>

      <v-btn block dark class="py-8 mt-4 text-h6" rounded>
        <a href="tel:0492116863" class="text-decoration-none white--text font-weight-black">049-211-6863</a>
      </v-btn>    

      <v-row style="height:100px;">
       
      </v-row>

      
    </v-container>

  </v-dialog>

</template>

<script>
export default {
  name: "PopMenu",

  data: () => ({
    menu: [
      {
        parent: {title:'about',link:'About'},
        children: [
          {title:'concept',link:'Concept'},
          {title:'reason_selected',link:'Reason'}
        ]
      },
      // {
      //   parent: {title:'event',link:'Event'},
      //   children: [
          
      //   ]
      // },
      {
        parent: {title:'staff_introduce',link:'Home'},
        children: [

        ]
      },
      {
        parent: {title:'company_summary',link:'CompanySummary'},
        children: [

        ]
      },
      {
        parent: {title:'recent_news',link:'NewsList'},
        children: [

        ]
      },
      {
        parent: {title:'visit_booking',link:'Booking'},
        children: [

        ]
      },
      {
        parent: {title:'inquiry',link:'Inquiry'},
        children: [

        ]
      },
      {
        parent: {title:'privacy_policy',link:'Privacy'},
        children: [

        ]
      }
    ]
  }),
  methods: {
    routerClicked(routerName) {
      this.$emit('routerClick')
      
      if(routerName == 'Concept') {
        this.$router.push({name: 'About',query: {p:'concept'}})
      } else if(routerName == 'Reason') {
        this.$router.push({name: 'About',query: {p:'reason'}})
      } else {
        this.$router.push({name: routerName})
      }
    },
    bannerClicked() {
      this.$emit('routerClick')
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>

  div.row.mx-1:after {
    content: "";
    width: 8px;
    height: 8px;
    margin: auto;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    transform: rotate(45deg);
  }

  .border {
    border-top: solid 1px #e6e5e5;
  }

</style>