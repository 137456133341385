<template>
  <div>
    <v-carousel 
      hide-delimiters
      cycle
      :show-arrows="false"
      interval="3000"
      progress
      progress-color="pink lighten-1"
    >
      <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        :src="item.src"
        transition="expand-transition"
      ></v-carousel-item>
    </v-carousel>
    <Concept class="mt-8"/>
    <Reason class="px-4 py-10 mt-8" style="background-color:#f4f4f4"/>
    <News />
    <Support />
    <Staff />
    <Company />
    <Sns />
    <Footer />
  </div>
</template>

<script>
import Concept from "../components/Concept.vue"
import Reason from "../components/Reason.vue"
import News from "../components/News.vue"
import Staff from '../components/Staff.vue'
import Company from '../components/Company.vue'
import Sns from '../components/Sns.vue'
import Footer from '../components/Footer.vue'

export default {
  name: "Home",

  data: () => ({
    items: [
      {
        src: require('../assets/modelroom_img01.jpeg')
      },
      {
        src: require('../assets/modelroom_img02.jpeg'),
      },
      {
        src: require('../assets/modelroom_img03.jpeg'),
      },
      {
        src: require('../assets/modelroom_img04.jpeg'),
      },
      {
        src: require('../assets/modelroom_img05.jpeg'),
      },
      {
        src: require('../assets/modelroom_img06.jpeg'),
      },
      {
        src: require('../assets/modelroom_img07.jpeg'),
      },
    ]
  }),

  components: {
    Concept,
    Reason,
    News,
    Staff,
    Company,
    Sns,
    Footer
  },
};
</script>
