<template>
 
<v-speed-dial
  v-model="fab"
  absolute
  fixed
  right
  bottom
  direction="top"
  transition="slide-y-reverse-transition"
  class="mb-12"
  v-if="$route.name == 'Home'"
>
  <template v-slot:activator>
    <v-btn
      v-model="fab"
      color="blue darken-2"
      dark
      fab
    >
      <v-icon v-if="fab">
        mdi-close
      </v-icon>
      <v-icon v-else>
        mdi-translate
      </v-icon>
    </v-btn>
  </template>

  <template v-for="item in languages">
    <v-btn
      :key="item.value"
      dark
      small
      fab
      @click="changeLanguage(item.value)"
    >
      <v-img :src="item.country" width="40" height="40" />
    </v-btn>
  </template>

</v-speed-dial>

</template>

<script>
export default {
  name: "ChangeLanguage",

  data: () => ({
    languages: [
      { country: require('../assets/china.png'), value: 'zhHans' },
      { country: require('../assets/japan.png'), value: 'ja' },
      { country: require('../assets/america.png'), value: 'en' },
    ],
    fab: false,
  }),
  methods: {
    changeLanguage(destLanguage) {
      this.$vuetify.lang.current = destLanguage
      console.log(this.$vuetify.lang.current)
    }
  }
};
</script>
