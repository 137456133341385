<template>
  <div>
    <v-container>
      <Header :title="$vuetify.lang.t('$vuetify.header_title.news_list')"/>
      
      <template v-for="item in items">
        <v-card :key="item.id" flat height="50" class="my-4">
          <router-link :to="{name: 'NewsDetail', params: {
            id: item.id,
            }}" 
            class="text-decoration-none">
            <v-row>
              <v-col cols="3">
                <v-img :src="require(`../assets/${item.imgSrc}`)" height="50" width="100" />
              </v-col>
              <v-col cols="9" class="text-truncate brown--text">
                <span class="error--text font-weight-bold">
                  {{ item.date }}
                  <v-chip label x-small class="success font-weight-bold mb-1 ml-2">
                    {{ item.iconText }}
                  </v-chip>
                </span><br>
                <span class="text-subtitle-1 font-weight-bold brown--text align-center">
                  {{ item.title }}
                </span>
              </v-col>
            </v-row>
          </router-link>
        </v-card>
        <v-divider :key="item.id"/>
      </template>
    </v-container>
  </div>
</template>

<script>
import Header from "../components/Header.vue"

export default {
  name: "NewsList",

  data: () => ({
    items: []
  }),
  created() {
    this.getNewsList()
  },
  methods: {
    getNewsList() {
      this.$http.get('/news-list', {
        params: {
          type: 'list',
          locale: this.$vuetify.lang.current
        }
      })
      .then(response => {
        console.log(response.data)
        this.items = response.data
      })
    }
  },
  components: {
    Header
  }

}
</script>
