<template>
  <v-card flat>
    <v-img src="../assets/reason.jpg" />
    
    <div class="font-weight-medium mt-4">
      <span class="text-h4">「ひと」</span>
      <span class="orange--text text--lighten-4 text-h2 float-right mt-n2">01</span>
      <div class="text-subtitle-1">
        とのコミュニケーション<br />
        新規開店のサポートも精一杯させていただきます。
      </div>
      <v-divider class="grey darken-2 mt-4"/>
    </div>

    <div class="font-weight-medium mt-4">
      <span class="text-h4">「まち」</span>
      <span class="orange--text text--lighten-4 text-h2 float-right mt-n2">02</span>
      <div class="text-subtitle-1">
        とのコミュニケーション <br />
        東武東上線沿線の和光市から川越市を中心
        としたエリアにスポットを当てています。
      </div>
      <v-divider class="grey darken-2 mt-4"/>
    </div>

    <div class="font-weight-medium mt-4 mb-3">
      <span class="text-h4">「くらし」</span>
      <span class="orange--text text--lighten-4 text-h2 float-right mt-n2">03</span>
      <div class="text-subtitle-1">
        のコミュニケーションへ<br />
        都心部と違い、
        徐々に増えていく外国人居住者を受け入れ、適応をフォローし、共生のためのコミュニテ
        ィーの役割を果たすこと、これも RAKUMARU 不動産の取り組みです。
      </div>
      <v-divider class="grey darken-2 mt-4"/>
    </div>

    <div class="d-flex justify-center">
      <v-btn
        outlined
        rounded
        class="mt-10"
        style="background-color:white;width:80%;text-transform:none;"
        @click="$router.push({name:'About'})"
        height="50"
      >
        <span>{{ $vuetify.lang.t('$vuetify.router_button.about') }}</span>
      </v-btn>
    </div>
  </v-card>
</template>

<script>

export default {
  name: "Reason",

  data: () => ({
   
  }),

}
</script>
