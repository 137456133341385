<template>
  <v-container>
    
    <v-row justify="center" class="myGrey white--text text-h5 py-5">
      {{ title }}
    </v-row>
    
  </v-container>
</template>

<script>

export default {
  name: "Header",

  data: () => ({
   
  }),
  props: ['title']

}
</script>

<style lang="scss" scoped>
  .myGrey {
    background-color: #cccccc
  }
</style>
