import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

import en from '@/locale/en.js'
import ja from '@/locale/ja.js'
import zhHans from '@/locale/zhHans.js'

export default new Vuetify({
    lang: {
        locales: { en, ja, zhHans },
        current: 'zhHans'
    }
});
