<template>
  <div class="mt-6">
    <div class="text-center text-h5 font-weight-bold">NEWS</div>
    <div class="yellow--text text--darken-4 text-center font-weight-bold mb-4">RAKUMARUの最新情報をお届けします。</div>

    <v-carousel
      v-model="model"
      height="400"
      hide-delimiter-background
      light
    >
      <v-carousel-item v-for="item in items" :key="item.id" @click="$vuetify.goTo(0,{duration:0});$router.push({
        name: 'NewsDetail', params:{
          id: item.id
        }})">
        <v-img :src="require('../assets/' + item.imgSrc)" height="300"/>
        <div class="error--text font-weight-bold ml-2 mt-2">
          {{ item.date }}
          <v-chip label x-small class="success font-weight-bold mb-1 ml-2">
            {{ item.iconText }}
          </v-chip>
          <div class="text-truncate">
            {{ item.title }}
          </div>
        </div>
      </v-carousel-item>
    </v-carousel>
    <div class="d-flex justify-center pt-2 pb-8">
      <v-btn
        outlined
        rounded
        style="background-color:white;width:80%;text-transform:none;"
        @click="$vuetify.goTo(0,{duration:0});$router.push({name:'NewsList'})"
        height="50"
      >
        {{ $vuetify.lang.t('$vuetify.router_button.news') }}
      </v-btn>
    </div>
  </div>
</template>

<script>

export default {
  name: "News",

  data: () => ({
    items: []
  }),
  created() {
    this.getTopNews()
  },
  methods: {
    getTopNews() {
      this.$http.get('/news-list', {
        params: {
          type: 'top',
          locale: this.$vuetify.lang.current
        }
      })
      .then(response => {
        console.log(response.data)
        this.items = response.data
      })
    }
  }

}
</script>