import { en } from 'vuetify/lib/locale'

export default {
    ...en,
  
    header_title: {
      inquiry: 'Inquiry',
      booking: 'Visit Booking',
      event: 'Event',
      about: 'About RAKUMARU',
      event_detail: 'Event Detail',
      event_booking: 'Event Booking',
      news_list: 'News List',
      news_detail: 'News Detail',
      company_summary: 'Company Summary',
      privacy: 'Privacy Policy'
    },
    
    bottom_nav: {
      inquiry: 'inquiry',
      booking: 'visit booking',
      //event: 'event',
      event: 'coming soon',
    },

    router_button: {
      about: 'About RAKUMARU',
      news: 'News list',
      support: 'See detail'
    },
    
    menu: {
      about: 'About RAKUMARU ESTATE',
      concept: 'Concept',
      reason_selected: 'Reason for chosen',
      event: 'Event information',
      support: 'support',
      guarantee: 'After service・guarantee',
      frequently_asked_questions: 'Frequently asked questions',
      staff_introduce: 'Staff introduce',
      company_summary: 'Company summary',
      recent_news: 'Recent news',
      visit_booking: 'Visit booking',
      inquiry: 'Inquiry',
      privacy_policy: 'Privacy policy'
    },

    form: {
      input: 'Input',
      confirm: 'Confirm',
      complete: 'Complete',
      required: 'required',
      optional: 'optional',
      btn_confirm: 'confirm',
      btn_submit: 'submit',
      btn_back: 'back',
      btn_back_home: 'back to home'
    },

    dialog: {
      already_reserved: 'You have already reserved this event',
      close: '关闭'
    },

    inquiry: {
      name: 'Name',
      ph_name: 'e.g.）Jack Dawson',
      furigana: 'Furigana',
      ph_furigana: 'e.g.）Jack Dawson',
      mobile: 'Mobile',
      ph_mobile: 'e.g.）090-1234-5678',
      mail: 'Mail',
      ph_mail: 'e.g.）yourname@domain.com',
      post_code: 'Post code',
      ph_post_code: 'e.g.）123456',
      prefecture: 'Prefecture',
      ph_prefecture: 'e.g.）naganoken',
      address: 'Address',
      ph_address: 'e.g.）kitano3-13-6, niizashi, saitamaken',
      question: 'Question',
      ph_question: 'Please write the content of your inquiry.',
      v_name: 'please input your name',
      v_furigana: 'please input your furigana',
      v_mobile: 'please input your mobile number',
      v_mail: 'please input your mail address',
      v_mail_invalid: 'please input a valid mail address',
      v_post_code: 'please input your post code',
      v_prefecture: 'please select your prefecture',
      v_address: 'please input your address',
      v_question: 'please input your content of inquiry',
      v_question_over: 'content is over 500 characters',
      complete: 'Thank you for inquiring.'
    },

    booking: {
      visit_day: 'Visit day',
      ph_visit_day: 'select visit day from calendar',
      visit_time: 'Visit time',
      ph_visit_time: 'select visit time from clock',
      name: 'Name',
      ph_name: 'e.g.）Jack Dawson',
      furigana: 'Furigana',
      ph_furigana: 'e.g.）Jack Dawson',
      mobile: 'Mobile',
      ph_mobile: 'e.g.）090-1234-5678',
      mail: 'Mail',
      ph_mail: 'e.g.）yourname@domain.com',
      post_code: 'Post code',
      ph_post_code: 'e.g.）123456',
      prefecture: 'Prefecture',
      ph_prefecture: 'e.g.）naganoken',
      address: 'Address',
      ph_address: 'e.g.）kitano3-13-6, niizashi, saitamaken',
      question: 'Question',
      ph_question: 'Please write the content of your inquiry.',
      v_name: 'please input your name',
      v_furigana: 'please input your furigana',
      v_mobile: 'please input your mobile number',
      v_mail: 'please input your mail address',
      v_mail_invalid: 'please input a valid mail address',
      v_post_code: 'please input your post code',
      v_prefecture: 'please select your prefecture',
      v_address: 'please input your address',
      v_question: 'please input your content of inquiry',
      v_question_over: 'content is over 500 characters',
      v_visit_day: 'please select visit day',
      v_visit_time: 'please select visit time',
      complete: 'Thank you for booking.'
    },

    event_booking: {
      event_name: 'Event name',
      event_day: 'Event day',
      event_time: 'Start time',
      name: 'Name',
      ph_name: 'e.g.）Jack Dawson',
      furigana: 'Furigana',
      ph_furigana: 'e.g.）Jack Dawson',
      mobile: 'Mobile',
      ph_mobile: 'e.g.）090-1234-5678',
      mail: 'Mail',
      ph_mail: 'e.g.）yourname@domain.com',
      post_code: 'Post code',
      ph_post_code: 'e.g.）123456',
      prefecture: 'Prefecture',
      ph_prefecture: 'e.g.）naganoken',
      address: 'Address',
      ph_address: 'e.g.）kitano3-13-6, niizashi, saitamaken',
      question: 'Question',
      ph_question: 'Please write the content of your inquiry.',
      v_name: 'please input your name',
      v_furigana: 'please input your furigana',
      v_mobile: 'please input your mobile number',
      v_mail: 'please input your mail address',
      v_mail_invalid: 'please input a valid mail address',
      v_post_code: 'please input your post code',
      v_prefecture: 'please select your prefecture',
      v_address: 'please input your address',
      v_question: 'please input your content of inquiry',
      v_question_over: 'content is over 500 characters',
      complete: 'Thank you for booking.'
    },

    event: {
      title: ` 's event`
    },

    event1: {
      title: 'opening campaign',
      content: 'content 111'
    },
    event2: {
      title: 'home loan seminar',
      content: 'content 222'
    },
    event3: {
      title: 'model room visit',
      content: 'content 333'
    },

    event_detail: {
      column1: 'Start Time',
      column2: 'Reservation / Status',
      reservation: 'reservation',
      success: 'many',
      warning: 'exist',
      error: 'few',
      grey: 'unreservationable'
    },

    footer: {
      address: 'T2 - 2 fujimono tonarie 2 - 10 - 37 ureshino fujiminoshi saitamaken',
      inquiry: 'inquiry here',
      business_hours: 'business hours',
      day_off: '（Sunday day off）'
    },

    key1: "How are you!",
    key2: "key 2 internationalization",
  
    namespace: {
      key3: "key 3 internationalization"
    }
}