<template>
  <div>
    <v-bottom-navigation
      app
      grow
      style="z-index:204"
      v-if="$route.name != 'Inquiry' && $route.name != 'Booking' && $route.name != 'EventBooking'"
    >
      <v-btn class="font-weight-black white" @click="$vuetify.goTo(0,{duration:0});$router.push({name:'Inquiry'})">
        <span>{{ $vuetify.lang.t('$vuetify.bottom_nav.inquiry') }}</span>
        <v-icon>mdi-file-document-outline</v-icon>
      </v-btn>

      <v-btn class="font-weight-black white" @click="$vuetify.goTo(0,{duration:0});$router.push({name:'Booking'})">
        <span>{{ $vuetify.lang.t('$vuetify.bottom_nav.booking') }}</span>
        <v-icon>mdi-calendar-check-outline</v-icon>
      </v-btn>

      <!-- <v-btn class="font-weight-black orange" @click="$vuetify.goTo(0,{duration:0});$router.push({name:'Event'})"> -->
      <v-btn class="font-weight-black green lighten-3">
        <span class="white--text">{{ $vuetify.lang.t('$vuetify.bottom_nav.event') }}</span>
        <v-icon class="white--text">mdi-calendar-text-outline</v-icon>
      </v-btn>  
      <HamburgerMenu @menuClick="toggleMenu" ref="hm"/> 
    </v-bottom-navigation>

    <PopMenu :show="dialog" @routerClick="toggleButton"/>
  </div>

</template>

<script>

import HamburgerMenu from "./HamburgerMenu.vue"
import PopMenu from "./PopMenu.vue"

export default {
  name: "BottomNav",

  data: () => ({
    dialog: false
  }),
  methods: {
    toggleMenu(value) {
      this.dialog = value
    },
    toggleButton() {
      this.$refs.hm.cbClick()
    },
  },
  components: {
    HamburgerMenu,
    PopMenu
  }
}
</script>
