<template>
  <div>
    <v-container>
      <Header :title="$vuetify.lang.t('$vuetify.header_title.event_detail')"/>
      <v-img :src="require(`../assets/event/${imgSrc}`)" class="mt-4" />
      <div class="mt-2 text-center text-h6 font-weight-bold warning--text">
        {{ date }}
      </div>
      <div class="mt-2 text-center text-h5 font-weight-bold">
        {{ $vuetify.lang.t(`$vuetify.event${id}.title`) }}
      </div>
      <div class="mt-2 text-subtitle-1">
        {{ $vuetify.lang.t(`$vuetify.event${id}.content`) }}
      </div>

      <v-row class="mt-3 font-weight-bold">
        <v-col cols="5" align="center">{{ $vuetify.lang.t('$vuetify.event_detail.column1') }}</v-col>
        <v-col cols="7" align="center">{{ $vuetify.lang.t('$vuetify.event_detail.column2') }}</v-col>
      </v-row>
      <v-divider class="my-2"/>

      <template v-for="item in reservation">
        <v-row :key="item.time">
          <v-col cols="5" align="center" align-self="center" class="text-subtitle-1 font-weight-bold">
            {{ item.time }}
          </v-col>
          <v-col cols="7" align="center">
            <v-btn 
              min-width="180"
              rounded
              :class="getClass(item.status)"
              :disabled="item.status==4" 
              @click="reservationClicked(item.time)"
              >
              {{ $vuetify.lang.t('$vuetify.event_detail.reservation') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-divider :key="item.time" class="my-2"/>
      </template>

      <v-row class=" px-2 text-caption font-weight-bold justify-space-around" align="center" style="height:50px;">
        <div>
          <v-chip x-small label class="success"></v-chip> {{ $vuetify.lang.t('$vuetify.event_detail.success') }}
        </div>
        <div>
          <v-chip x-small label class="warning"></v-chip> {{ $vuetify.lang.t('$vuetify.event_detail.warning') }}
        </div>
        <div>
          <v-chip x-small label class="error"></v-chip> {{ $vuetify.lang.t('$vuetify.event_detail.error') }}
        </div>
        <div>
          <v-chip x-small label class="grye"></v-chip> {{ $vuetify.lang.t('$vuetify.event_detail.grey') }}
        </div>
      </v-row>
      

    </v-container>
  </div>
</template>

<script>

import Header from "../components/Header.vue"

export default {
  name: "EventDetail",

  data: () => ({
    imgSrc: '',
    id: '',
    date: '',
    events: [
      {
        id: '1',
        img: 'open.jpg'
      },
      {
        id: '2',
        img: 'loan.jpg'
      },
      {
        id: '3',
        img: 'model_room.jpg'
      }
    ],
    reservation: [
      {
        time: '10:00 - 12:00',
        status: '0'
      },
      {
        time: '13:00 - 15:00',
        status: '1'
      },
      {
        time: '15:00 - 17:00',
        status: '4'
      }
    ]
  }),
  created() {
    this.date = this.$route.params.date
    this.id = this.$route.params.id
    this.imgSrc = this.events.filter(e => e.id == this.id)[0].img
  },
  methods: {
    getClass(status) {
      var btnClass = ''
      if(status == '0') {
        btnClass = 'success font-weight-bold'
      } else if(status == '1') {
        btnClass = 'warning font-weight-bold'
      } else {
        btnClass = 'error font-weight-bold'
      }

      return btnClass
    },
    reservationClicked(time) {
      this.$router.push({
        name: 'EventBooking',
        params: {
          id: this.id,
          eventName: this.$vuetify.lang.t('$vuetify.event' + this.id + '.title'),
          date: this.date,
          time: time
        }
      })
    }
  },
  mounted () {
  },
  components: {
    Header
  },
}
</script>

<style lang="scss" scoped>

</style>
