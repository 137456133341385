<template>
  <v-form ref="form">
    <v-container>
      <Header :title="$vuetify.lang.t('$vuetify.header_title.booking')"/>
      
      <v-stepper
        v-model="e1"
        vertical
        flat
        class="ml-n6"
      >
        <v-stepper-step
          :complete="e1 > 1"
          step="1"
        >
          {{ $vuetify.lang.t('$vuetify.form.input') }}
          <!-- <small>Summarize if needed</small> -->
        </v-stepper-step>

        <v-stepper-content step="1">

          <v-card flat class="font-weight-bold text-subtitle-2">
            
            <v-chip label x-small class="error mr-2 mb-1">
              {{ $vuetify.lang.t('$vuetify.form.required') }}
            </v-chip>
            {{ $vuetify.lang.t('$vuetify.booking.visit_day') }}
            
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="visitDay"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="mt-n1"
                  :placeholder="$vuetify.lang.t('$vuetify.booking.ph_visit_day')"
                  :rules="[visitDayRules]"
                  ref="visitDay"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="visitDay"
                @input="menu1 = false"
                full-width
              ></v-date-picker>
            </v-menu>
            
            <v-chip label x-small class="error mr-2 mb-1">
              {{ $vuetify.lang.t('$vuetify.form.required') }}
            </v-chip>
            {{ $vuetify.lang.t('$vuetify.booking.visit_time') }}
            
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              
              ref="menu"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="visitTime"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="mt-n1"
                  :placeholder="$vuetify.lang.t('$vuetify.booking.ph_visit_time')"
                  :rules="[visitTimeRules]"
                  ref="visitTime"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="visitTime"
                full-width
                @click:minute="$refs.menu.save(visitTime)"
                format="24hr"
                :allowed-hours="allowedHours"
                :allowed-minutes="allowedMinutes"
              ></v-time-picker>
            </v-menu>


            <v-chip label x-small class="error mr-2 mb-1">
              {{ $vuetify.lang.t('$vuetify.form.required') }}
            </v-chip>
            {{ $vuetify.lang.t('$vuetify.inquiry.name') }}
            <v-text-field 
              v-model="name"
              class="mt-n1"
              :placeholder="$vuetify.lang.t('$vuetify.inquiry.ph_name')"
              :rules="[nameRules]"
              ref="name"
            >
            </v-text-field>

            <v-chip label x-small class="error mr-2 mb-1">
              {{ $vuetify.lang.t('$vuetify.form.required') }}
            </v-chip>
            {{ $vuetify.lang.t('$vuetify.inquiry.furigana') }}
            <v-text-field 
              v-model="furigana"
              class="mt-n1"
              :placeholder="$vuetify.lang.t('$vuetify.inquiry.ph_furigana')"
              :rules="[furiganaRules]"
              ref="furigana"
            >
            </v-text-field>

            <v-chip label x-small class="error mr-2 mb-1">
              {{ $vuetify.lang.t('$vuetify.form.required') }}
            </v-chip>
            {{ $vuetify.lang.t('$vuetify.inquiry.mobile') }}
            <v-text-field 
              v-model="mobile"
              class="mt-n1"
              :placeholder="$vuetify.lang.t('$vuetify.inquiry.ph_mobile')"
              :rules="[mobileRules]"
              ref="mobile"
            >
            </v-text-field>

            <v-chip label x-small class="error mr-2 mb-1">
              {{ $vuetify.lang.t('$vuetify.form.required') }}
            </v-chip>
            {{ $vuetify.lang.t('$vuetify.inquiry.mail') }}
            <v-text-field
              v-model="mail" 
              class="mt-n1"
              :placeholder="$vuetify.lang.t('$vuetify.inquiry.ph_mail')"
              :rules="[mailRules]"
              ref="mail"
            >
            </v-text-field>

            <v-chip label x-small class="grey mr-2 mb-1" dark>
              {{ $vuetify.lang.t('$vuetify.form.optional') }}
            </v-chip>
            {{ $vuetify.lang.t('$vuetify.inquiry.post_code') }}
            <v-text-field 
              v-model="postCode"
              maxlength="7"
              :placeholder="$vuetify.lang.t('$vuetify.inquiry.ph_post_code')"
              @keyup="keyTrigger"
              :rules="[postCodeRules]"
              ref="postCode"
            >
            </v-text-field>

            <v-chip label x-small class="grey mr-2 mb-1" dark>
              {{ $vuetify.lang.t('$vuetify.form.required') }}
            </v-chip>
            {{ $vuetify.lang.t('$vuetify.inquiry.prefecture') }}
            <v-select 
              v-model="prefecture"
              :items="items" 
              class="mt-n1"
              :placeholder="$vuetify.lang.t('$vuetify.inquiry.ph_prefecture')"
              :rules="[prefectureRules]"
              ref="prefecture"
            >
            </v-select>

            <v-chip label x-small class="grey mr-2 mb-1" dark>
              {{ $vuetify.lang.t('$vuetify.form.required') }}
            </v-chip>
            {{ $vuetify.lang.t('$vuetify.inquiry.address') }}
            <v-text-field 
              v-model="address"
              class="mt-n1"
              :placeholder="$vuetify.lang.t('$vuetify.inquiry.address')"
              :rules="[addressRules]"
              ref="address"
            >
            </v-text-field>

            <v-chip label x-small class="grey mr-2 mb-1" dark>
              {{ $vuetify.lang.t('$vuetify.form.required') }}
            </v-chip>
            {{ $vuetify.lang.t('$vuetify.inquiry.question') }}
            <v-textarea 
              v-model="question"
              outlined 
              height="100" 
              class="mt-4"
              :counter="500"
              :placeholder="$vuetify.lang.t('$vuetify.inquiry.ph_question')"
              :rules="[questionRules]"
              ref="question"
            >
            </v-textarea>


          </v-card>
            
          <v-btn block rounded dark
            color="yellow darken-4"
            @click="confirm"
            class="font-weight-bold text-h6"
          >
            {{ $vuetify.lang.t('$vuetify.form.btn_confirm') }}
          </v-btn>
        </v-stepper-content>

        <v-stepper-step
          :complete="e1 > 2"
          step="2"
        >
          {{ $vuetify.lang.t('$vuetify.form.confirm') }}
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-card flat class="font-weight-bold text-subtitle-2">
            <v-chip label x-small class="error mr-2 mb-1">
              {{ $vuetify.lang.t('$vuetify.form.required') }}
            </v-chip>
            {{ $vuetify.lang.t('$vuetify.booking.visit_day') }}
            <div class="text-subtitle-1 font-weight-bold grey--text text--darken-2 mb-3">
              {{ this.visitDay }}
              <v-divider />
            </div>
            
            <v-chip label x-small class="error mr-2 mb-1">
              {{ $vuetify.lang.t('$vuetify.form.required') }}
            </v-chip>
            {{ $vuetify.lang.t('$vuetify.booking.visit_time') }}
            <div class="text-subtitle-1 font-weight-bold grey--text text--darken-2 mb-3">
              {{ this.visitTime }}
              <v-divider />
            </div>

            <v-chip label x-small class="error mr-2 mb-1">
              {{ $vuetify.lang.t('$vuetify.form.required') }}
            </v-chip>
            {{ $vuetify.lang.t('$vuetify.inquiry.name') }}
            <div class="text-subtitle-1 font-weight-bold grey--text text--darken-2 mb-3">
              {{ this.name }}
              <v-divider />
            </div>

            <v-chip label x-small class="error mr-2 mb-1">
              {{ $vuetify.lang.t('$vuetify.form.required') }}
            </v-chip>
            {{ $vuetify.lang.t('$vuetify.inquiry.furigana') }}
            <div class="text-subtitle-1 font-weight-bold grey--text text--darken-2 mb-3">
              {{ this.furigana }}
              <v-divider />
            </div>

            <v-chip label x-small class="error mr-2 mb-1">
              {{ $vuetify.lang.t('$vuetify.form.required') }}
            </v-chip>
            {{ $vuetify.lang.t('$vuetify.inquiry.mobile') }}
            <div class="text-subtitle-1 font-weight-bold grey--text text--darken-2 mb-3">
              {{ this.mobile }}
              <v-divider />
            </div>

            <v-chip label x-small class="error mr-2 mb-1">
              {{ $vuetify.lang.t('$vuetify.form.required') }}
            </v-chip>
            {{ $vuetify.lang.t('$vuetify.inquiry.mail') }}
            <div class="text-subtitle-1 font-weight-bold grey--text text--darken-2 mb-3">
              {{ this.mail }}
              <v-divider />
            </div>

            <v-chip label x-small class="grey mr-2 mb-1" dark>
              {{ $vuetify.lang.t('$vuetify.form.optional') }}
            </v-chip>
            {{ $vuetify.lang.t('$vuetify.inquiry.post_code') }}
            <div class="text-subtitle-1 font-weight-bold grey--text text--darken-2 mb-3">
              {{ this.postCode ? this.postCode : '-'}}
              <v-divider />
            </div>

            <v-chip label x-small class="grey mr-2 mb-1" dark>
              {{ $vuetify.lang.t('$vuetify.form.optional') }}
            </v-chip>
            {{ $vuetify.lang.t('$vuetify.inquiry.prefecture') }}
            <div class="text-subtitle-1 font-weight-bold grey--text text--darken-2 mb-3">
              {{ this.prefecture ? this.prefecture : '-' }}
              <v-divider />
            </div>

            <v-chip label x-small class="grey mr-2 mb-1" dark>
              {{ $vuetify.lang.t('$vuetify.form.optional') }}
            </v-chip>
            {{ $vuetify.lang.t('$vuetify.inquiry.address') }}
            <div class="text-subtitle-1 font-weight-bold grey--text text--darken-2 mb-3">
              {{ this.address ? this.address : '-' }}
              <v-divider />
            </div>

            <v-chip label x-small class="grey mr-2 mb-1" dark>
              {{ $vuetify.lang.t('$vuetify.form.optional') }}
            </v-chip>
            {{ $vuetify.lang.t('$vuetify.inquiry.question') }}
            <div class="text-subtitle-1 font-weight-bold grey--text text--darken-2 mb-3" v-html="question.replace(/\n/g,'<br/>') ? question.replace(/\n/g,'<br/>') : '-'">
            </div>

            <v-btn rounded dark
              block
              color="yellow darken-4"
              @click="submit"
              class="font-weight-bold text-h6 mt-8"
            >
              {{ $vuetify.lang.t('$vuetify.form.btn_submit') }}
            </v-btn>
            
            <div class="text-center">
              <v-btn rounded dark
                block
                color="grey"
                @click="e1=1"
                class="font-weight-bold text-h6 mt-4 px-12"
          
              >
                {{ $vuetify.lang.t('$vuetify.form.btn_back') }}
              </v-btn>
            </div>
          </v-card>
        </v-stepper-content>

        <v-stepper-step
          :complete="e1 > 3"
          step="3"
        >
          {{ $vuetify.lang.t('$vuetify.form.complete') }}
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-card 
            flat 
            class="font-weight-bold text-h6 grey--text success--text text-center"
            v-html="$vuetify.lang.t('$vuetify.booking.complete')"
            height="250px"
          >
          </v-card>
          <v-btn block rounded dark
            color="primary"
            @click="$router.push({name:'Home'})"
            class="font-weight-bold text-h6"
          >
            {{ $vuetify.lang.t('$vuetify.form.btn_back_home') }}
          </v-btn>

        </v-stepper-content>

      </v-stepper>

      <v-progress-circular
        :size="100"
        :width="15"
        :value="value"
        :color="remain == 0 ? 'success' : 'red lighten-2'"
        style="position:fixed;bottom:40px;right:12px;"
        v-if="e1==1"
      >
        残<span class="font-weight-bold mx-1">{{ remain }}</span>項目
      </v-progress-circular>
    
    </v-container>
  </v-form>
</template>

<script>

import qs from "qs"
import Header from "../components/Header.vue"

export default {
  name: "Booking",

  data: () => ({
    e1: 1,
    menu1: false,
    visitDay: '',
    visitTime: '',
    name: '',
    furigana: '',
    mobile: '',
    mail: '',
    postCode: '',
    prefecture: '',
    address: '',
    question: '',
    errorFlag: false,
    value: 0,
    remain: 6,
    items: [
      '北海道',
      '青森県',
      '岩手県',
      '宮城県',
      '秋田県',
      '山形県',
      '福島県',
      '茨城県',
      '栃木県',
      '群馬県',
      '埼玉県',
      '千葉県',
      '東京都',
      '神奈川県',
      '新潟県',
      '富山県',
      '石川県',
      '福井県',
      '山梨県',
      '長野県',
      '岐阜県',
      '静岡県',
      '愛知県',
      '三重県',
      '滋賀県',
      '京都府',
      '大阪府',
      '兵庫県',
      '奈良県',
      '和歌山県',
      '鳥取県',
      '島根県',
      '岡山県',
      '広島県',
      '山口県',
      '徳島県',
      '香川県',
      '愛媛県',
      '高知県',
      '福岡県',
      '佐賀県',
      '長崎県',
      '熊本県',
      '大分県',
      '宮崎県',
      '鹿児島県',
      '沖縄県',
    ],
    remainCheck: {
      visitDay: false,
      visitTime: false,
      name: false,
      furigana: false,
      mobile: false,
      mail: false,
    }
  }),
  created() {
    
  },
  methods: {
    allowedHours: v => v >=10 && v <=16,
    allowedMinutes: v => v % 5 == 0,
    setAddress(code) {
      this.$http.get('https://api.zipaddress.net/?zipcode=' + code)
        .then(respnose => {
          let jsonData = respnose.data

          if(jsonData.code == 200) {
            this.prefecture = jsonData.data.pref
            this.address = jsonData.data.address
          }
        })
    },
    keyTrigger() {
      
      if(this.postCode.length < 7)
        return
      
      var code = this.postCode.slice(0,3) + '-' + this.postCode.slice(3)
      this.setAddress(code)
       
    },
    confirm() {
      this.errorFlag = false

      if(!this.$refs.form.validate()) {
        this.errorFlag = false
        return
      }
      
      this.e1 = 2
    },
    submit() {
      this.$http.post('/create-booking', qs.stringify({
        visitDay: this.visitDay,
        visitTime: this.visitTime,
        name: this.name,
        furigana: this.furigana,
        mobile: this.mobile,
        mail: this.mail,
        postCode: this.postCode,
        prefecture: this.prefecture,
        address: this.address,
        question: this.question
      })).then(response => {
        if(response.data.result == "ok") {
          this.e1 = 3
        }
      })
    },
    visitDayRules() {
      if(!this.visitDay) {
        
        if(!this.errorFlag) {
          this.errorFlag = true
          this.$refs.visitDay.focus()
        }
        
        if(this.remainCheck.visitDay) {
          this.value -= 100/6
          this.remain += 1
          this.remainCheck.visitDay = false
        }

        return this.$vuetify.lang.t('$vuetify.booking.v_visit_day')
      
      } else {
        
        if(!this.remainCheck.visitDay) {
          this.value += 100/6
          this.remain -= 1
          this.remainCheck.visitDay = true
        }
      
      }
    },
    visitTimeRules() {
      if(!this.visitTime) {
        
        if(!this.errorFlag) {
          this.errorFlag = true
          this.$refs.visitTime.focus()
        }
        
        if(this.remainCheck.visitTime) {
          this.value -= 100/6
          this.remain += 1
          this.remainCheck.visitTime = false
        }

        return this.$vuetify.lang.t('$vuetify.booking.v_visit_time')
      
      } else {
        
        if(!this.remainCheck.visitTime) {
          this.value += 100/6
          this.remain -= 1
          this.remainCheck.visitTime = true
        }
      
      }
    },
    nameRules() {
      if(!this.name) {
        
        if(!this.errorFlag) {
          this.errorFlag = true
          this.$refs.name.focus()
        }
        
        if(this.remainCheck.name) {
          this.value -= 100/6
          this.remain += 1
          this.remainCheck.name = false
        }

        return this.$vuetify.lang.t('$vuetify.inquiry.v_name')
      
      } else {
        
        if(!this.remainCheck.name) {
          this.value += 100/6
          this.remain -= 1
          this.remainCheck.name = true
        }
      
      }
    },
    furiganaRules() {
      if(!this.furigana) {
        
        if(!this.errorFlag) {
          this.errorFlag = true
          this.$refs.furigana.focus()
        }

        if(this.remainCheck.furigana) {
          this.value -= 100/6
          this.remain += 1
          this.remainCheck.furigana = false
        }
        
        return this.$vuetify.lang.t('$vuetify.inquiry.v_furigana')
      } else {

        if(!this.remainCheck.furigana) {
          this.value += 100/6
          this.remain -= 1
          this.remainCheck.furigana = true
        }

      }
    },
    mobileRules() {
      if(!this.mobile) {
        
        if(!this.errorFlag) {
          this.errorFlag = true
          this.$refs.mobile.focus()
        }

        if(this.remainCheck.mobile) {
          this.value -= 100/6
          this.remain += 1
          this.remainCheck.mobile = false
        }
        
        return this.$vuetify.lang.t('$vuetify.inquiry.v_mobile')
      
      } else {

        if(!this.remainCheck.mobile) {
          this.value += 100/6
          this.remain -= 1
          this.remainCheck.mobile = true
        }

      }
    },
    mailRules() {
      if(!this.mail) {
        
        if(!this.errorFlag) {
          this.errorFlag = true
          this.$refs.mail.focus()
        }

        if(this.remainCheck.mail) {
          this.value -= 100/6
          this.remain += 1
          this.remainCheck.mail = false
        }
        
        return this.$vuetify.lang.t('$vuetify.inquiry.v_mail')
      } else {
        var reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/
        if (!reg.test(this.mail)) {

          if(this.remainCheck.mail) {
            this.value -= 100/6
            this.remain += 1
            this.remainCheck.mail = false
          }

          return this.$vuetify.lang.t('$vuetify.inquiry.v_mail_invalid')
        } else {

          if(!this.remainCheck.mail) {
            this.value += 100/6
            this.remain -= 1
            this.remainCheck.mail = true
          }

        }
      }
    },
  },
  components: {
    Header
  },
}
</script>

<style lang="scss" scoped>
// .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
//   display: block !important;
// }
.my-text-style >>> .v-text-field__slot input {
    color: red
  }
</style>
