<template>
  <v-card flat class="mt-4 pt-6" style="background-color:#f4f4f4">
    <div class="cyan--text text--darken--2 text-center text-h5 font-weight-bold">STAFF</div>
    <div class="mt-6 pb-10 mx-4">
      <v-img src="../assets/staff.jpeg" />
    </div>
  </v-card>
</template>

<script>

export default {
  name: "Staff",

  data: () => ({
   
  }),

}
</script>
