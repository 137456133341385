<template>
  <div>
    <v-container>
      <Header :title="$vuetify.lang.t('$vuetify.header_title.privacy')"/>

      <div class="text-h6 font-weight-bold text-center mt-6">
        プライバシーポリシーの宣言
      </div>
      
      <div class="text-subtitle-2 mt-4">
        当社は、当社の運営するウェブサイトをご利用いただくお客様のプライバシーの保護に努めています。<br /><br />
        当社が、サービスを提供するためには、お客様個人に関する情報（以下、「個人情報」といいます）を集めなければなりませんが、
        当社でその情報のプライバシーを守り、秘密を保持するために様々な手段を講じています。当社ではお客様のプライバシーを尊重しています。<br/><br/>
        当社は個人情報を売買したり、交換したり、その他の方法で不正使用することには一切関与しておりません。 
        このウェブサイトをご利用になり、個人情報を供与することで、あなたはこのプライバシーポリシーに説明されている個人情報の取り扱い等について受諾し、
        承認したものとみなされます。
      </div>
      
    </v-container>
  </div>
</template>

<script>
import Header from "../components/Header.vue"

export default {
  name: "Privacy",

  data: () => ({
    
  }),
  components: {
    Header
  }

}
</script>
