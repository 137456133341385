<template>
  <div>
    <v-container>
      <Header :title="$vuetify.lang.t('$vuetify.header_title.event')"/>
      <v-sheet class="d-flex justify-space-around mt-4">
          <v-btn
            icon
            @click="$refs.calendar.prev()"
          >
            <v-icon class="purple--text font-weight-bold" large>mdi-chevron-left</v-icon>
          </v-btn>
          <div class="mt-1 text-h6 purple--text font-weight-bold" v-if="$refs.calendar">{{ $refs.calendar.title }}</div>
          <v-btn
            icon
            @click="$refs.calendar.next()"
          >
            <v-icon class="purple--text" large>mdi-chevron-right</v-icon>
          </v-btn>
      </v-sheet>
      <v-calendar 
        ref="calendar"
        v-model="value"
        locale="en"
        color="primary"
        :show-month-on-first="false"
        :events="events"
        @click:date="dateClicked"
        style="border:0px;"
        class="mt-4"
      >
      </v-calendar>

      <div v-if="dayEvents.length" class="mt-4 text-center text-h6 font-weight-bold warning--text">
        {{ daySelected + $vuetify.lang.t('$vuetify.event.title')}}
      </div>
      <template v-for="item in dayEvents">
        <v-card :key="item.id" flat height="50" class="my-4">
          <router-link :to="{name: 'EventDetail', params: {
            id: item.id,
            date: daySelected
            }}" class="text-decoration-none">
            <div>
              <v-img :src="require(`../assets/event/${item.img}`)" height="50" width="100" class="float-left"/>
              <span class="ml-2 text-subtitle-1 font-weight-bold brown--text align-center">{{ $vuetify.lang.t('$vuetify.event' + item.id + '.title') }}</span>
            </div>
          </router-link>
        </v-card>
        <v-divider :key="item.id"/>
      </template>
    </v-container>
  </div>
</template>

<script>

import Header from "../components/Header.vue"

export default {
  name: "Event",

  data: () => ({
    value: '',
    daySelected: '',
    dayEvents: [],
    events: [
      {
        id: '1',
        start: '2021-11-14',
        end: '2021-11-19',
        color: 'green',
        img: 'open.jpg'

      },
      {
        id: '2',
        start: '2021-11-16',
        end: '2021-11-16',
        color: 'pink',
        img: 'loan.jpg'
      },
      {
        id: '3',
        start: '2021-11-30',
        end: '2021-11-30',
        color: 'cyan',
        img: 'model_room.jpg'
      }
    ],
  }),
  methods: {
    dateClicked(obj) {
      let date = Date.parse(obj.date)
      this.dayEvents = this.events.filter(e => Date.parse(e.start) <= date && Date.parse(e.end) >= date)

      this.daySelected = obj.date
    },
    getEventColor(event) {
      return event.color
    },
    setCalendarStyle() {
      //right and bottom border of cell (current)
      var objs = document.getElementsByClassName('v-calendar-weekly__day')
      for(let i=0;i<objs.length;i++) {
        objs[i].style = 'border:0px';
      }
      
      //right border of head cell
      var objHeader = document.getElementsByClassName('v-calendar-weekly__head-weekday')
      for(let i=0;i<objHeader.length;i++) {
        objHeader[i].style = 'border:0px';
      }

      var objGray = document.getElementsByClassName('v-outside')
      for(let i=0;i<objGray.length;i++) {
        objGray[i].style = 'background-color:white;border:0px;';
      }
      
      //top and left
      document.getElementsByClassName('v-calendar-monthly')[0].style = 'border:0px;'
    }
  },
  mounted () {
    this.value = new Date().getFullYear() + '-' + ('00' + (new Date().getMonth() + 1)).slice(-2) + '-' + ('00' + new Date().getDate()).slice(-2)
  },
  components: {
    Header
  },
}
</script>

<style lang="scss" scoped>
  ::v-deep .v-calendar-weekly__day {
    border:0px !important;
  }

  ::v-deep .v-calendar-weekly__head-weekday {
    border:0px !important;
  }

  ::v-deep .v-btn__content {
    /*color:darkblue !important;*/
    font-size:large !important;
  }
</style>
