<template>
  <v-card flat class="my-12">
    <div class="d-flex justify-center">
      <v-chip outlined label class="font-weight-bold text-body-1 px-8 py-4 success red--text text--accent-3">
        Coming soon！
      </v-chip>
    </div>
    <div class="mt-6 d-flex justify-space-around">
      <v-btn large class="purple" dark icon>
        <v-icon>mdi-instagram</v-icon>
      </v-btn>
      <v-btn large class="blue darken-4" dark icon>
        <v-icon>mdi-facebook</v-icon>
      </v-btn>
      <v-btn large class="error" dark icon>
        <v-icon>mdi-youtube</v-icon>
      </v-btn>
      <v-btn large class="blue" dark icon>
        <v-icon>mdi-twitter</v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>

export default {
  name: "Footer",

  data: () => ({
   
  }),

}
</script>
