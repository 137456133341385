<template>
  <div>
    <v-container>
      <Header :title="$vuetify.lang.t('$vuetify.header_title.news_detail')"/>
      <v-img :src="require(`../assets/${imgSrc}`)" class="mt-4" />

      <div class="mt-2 text-center text-h5 font-weight-bold">
        {{ title }}
      </div>
      <div class="mt-2 text-subtitle-1">
        {{ content }}
      </div>   

    </v-container>
  </div>
</template>

<script>

import Header from "../components/Header.vue"

export default {
  name: "NewsDetail",

  data: () => ({
    imgSrc: '',
    title: '',
    content: '',
  }),
  created() {
    this.getNewsDetail()
  },
  methods: {
    getNewsDetail() {
      this.$http.get('/news-detail', {
        params: {
          id: this.$route.params.id,
          locale: this.$vuetify.lang.current
        }
      })
      .then(response => {
        console.log(response.data)
        this.imgSrc = response.data.imgSrc
        this.title = response.data.title
        this.content = response.data.content.replace(/@@cl@@/g,"\n")
      })
    }
  },
  mounted () {
  },
  components: {
    Header
  },
}
</script>

<style lang="scss" scoped>

</style>
