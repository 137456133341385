import { ja } from 'vuetify/lib/locale'

export default {
  ...ja,
  
  header_title: {
    inquiry: 'お問合せ',
    booking: '来場予約',
    event: 'イベント情報',
    about: 'RAKUMARUについて',
    event_detail: 'イベント詳細',
    event_booking: 'イベント予約',
    news_list: 'ニュース一覧',
    news_detail: 'ニュース詳細',
    company_summary: '会社概要',
    privacy: 'プライバシーポリシー'
  },
  
  bottom_nav: {
    inquiry: 'お問合わせ',
    booking: '来場予約',
    //event: 'イベント情報',
    event: '企画中',
  },
  
  router_button: {
    about: 'RAKUMARUについて',
    news: '一覧を見る',
    support: '詳細を見る'
  },

  menu: {
    about: 'RAKUMARU不動産について',
    concept: 'コンセプト',
    reason_selected: '選ばれた理由',
    event: 'エベント情報',
    support: 'サポート',
    guarantee: 'アフターフォロー・保証',
    frequently_asked_questions: 'よくある質問',
    staff_introduce: 'スターフ紹介',
    company_summary: '会社概要',
    recent_news: '新着情報',
    visit_booking: '来場予約',
    inquiry: 'お問い合わせ',
    privacy_policy: 'プライバシーポリシー'
  },

  form: {
    input: '入力',
    confirm: '確認',
    complete: '完了',
    required: '必須',
    optional: '任意',
    btn_confirm: '確認',
    btn_submit: '送信',
    btn_back: '戻る',
    btn_back_home: 'ホームへ戻る'
  },

  dialog: {
    already_reserved: '既に予約済みです',
    close: '閉じる'
  },

  inquiry: {
    name: 'お名前',
    ph_name: '例）山田 花子',
    furigana: 'ふりがな',
    ph_furigana: '例）やまだ はなこ',
    mobile: '携帯電話',
    ph_mobile: '例）090-1234-5678',
    mail: 'メールアドレス',
    ph_mail: '例）yourname@domain.com',
    post_code: '郵便番号',
    ph_post_code: '例）3520003',
    prefecture: '都道府県',
    ph_prefecture: '例）長野県',
    address: 'ご住所',
    ph_address: '例）埼玉県新座市北野3-13-6',
    question: 'ご質問・ご要望',
    ph_question: 'お問合せ内容をご記入ください',
    v_name: '名前を入力してください',
    v_furigana: 'ふりがなを入力してください',
    v_mobile: '携帯電話を入力してください',
    v_mail: 'メールアドレスを入力してください',
    v_mail_invalid: '正しいメールアドレスを入力してください',
    v_post_code: '郵便番号を入力してください',
    v_prefecture: '都道府県を選択してください',
    v_address: '住所を入力してください',
    v_question: '質問・要望を入力してください',
    v_question_over: '500文字を超えています',
    complete: '送信しました、<br/>ありがとうございました。',
  },

  booking: {
    visit_day: 'ご見学希望日',
    ph_visit_day: 'カレンダーから見学日を選択してください',
    visit_time: 'ご見学希望時間',
    ph_visit_time: '時計から見学時間を選択してください',
    name: 'お名前',
    ph_name: '例）山田 花子',
    furigana: 'ふりがな',
    ph_furigana: '例）やまだ はなこ',
    mobile: '携帯電話',
    ph_mobile: '例）090-1234-5678',
    mail: 'メールアドレス',
    ph_mail: '例）yourname@domain.com',
    post_code: '郵便番号',
    ph_post_code: '例）3520003',
    prefecture: '都道府県',
    ph_prefecture: '例）長野県',
    address: 'ご住所',
    ph_address: '例）埼玉県新座市北野3-13-6',
    question: 'ご質問・ご要望',
    ph_question: 'お問合せ内容をご記入ください',
    v_name: '名前を入力してください',
    v_furigana: 'ふりがなを入力してください',
    v_mobile: '携帯電話を入力してください',
    v_mail: 'メールアドレスを入力してください',
    v_mail_invalid: '正しいメールアドレスを入力してください',
    v_post_code: '郵便番号を入力してください',
    v_prefecture: '都道府県を選択してください',
    v_address: '住所を入力してください',
    v_question: '質問・要望を入力してください',
    v_question_over: '500文字を超えています',
    v_visit_day: '見学日を選択してください',
    v_visit_time: '見学時間を選択してください',
    complete: '予約しました、<br/>ありがとうございました。',
  },

  event_booking: {
    event_name: 'イベント名',
    event_day: 'イベント日',
    event_time: '開始時間',
    name: 'お名前',
    ph_name: '例）山田 花子',
    furigana: 'ふりがな',
    ph_furigana: '例）やまだ はなこ',
    mobile: '携帯電話',
    ph_mobile: '例）090-1234-5678',
    mail: 'メールアドレス',
    ph_mail: '例）yourname@domain.com',
    post_code: '郵便番号',
    ph_post_code: '例）3520003',
    prefecture: '都道府県',
    ph_prefecture: '例）長野県',
    address: 'ご住所',
    ph_address: '例）埼玉県新座市北野3-13-6',
    question: 'ご質問・ご要望',
    ph_question: 'お問合せ内容をご記入ください',
    v_name: '名前を入力してください',
    v_furigana: 'ふりがなを入力してください',
    v_mobile: '携帯電話を入力してください',
    v_mail: 'メールアドレスを入力してください',
    v_mail_invalid: '正しいメールアドレスを入力してください',
    v_post_code: '郵便番号を入力してください',
    v_prefecture: '都道府県を選択してください',
    v_address: '住所を入力してください',
    v_question: '質問・要望を入力してください',
    v_question_over: '500文字を超えています',
    complete: '予約しました、<br/>ありがとうございました。',
  },

  about: {
    concept: ''
  },

  event: {
    title: 'のイベント'
  },

  event1: {
    title: 'オーペンニングキャンペーン',
    content: 'コンテンツ１'
  },
  event2: {
    title: '住宅ローン相談会',
    content: 'コンテンツ２'
  },
  event3: {
    title: 'モデルルーム見学会',
    content: 'コンテンツ３'
  },

  event_detail: {
    column1: '開催時間',
    column2: '予約 / ステータス',
    reservation: 'この時間で予約する',
    success: '余裕がある',
    warning: '予約可',
    error: '残りわずか',
    grey: '予約不可'
  },

  news_detail: {
    
  },

  footer: {
    address: '埼玉县ふじみ野市うれし野2 - 10 - 37 トナリエふじみ野T2 - 2',
    inquiry: 'お問合せはこちら',
    business_hours: '営業時間',
    day_off: '（日曜日定休）'
  },

  key1: "こんにちは！",
  key2: "キー２　インターナショナリゼーション",
  
  namespace: {
    key3: "キー３　インターナショナリゼーション"
  }
}